<template>
  <v-container class="fill-height wena my-0" fluid>
    <v-row justify="center">
      <v-col
        cols="12"
        sm="8"
        md="4"
        lg="4"
        xl="4"
      >
        <v-system-bar
          color="secondary"
          dark
          class="pb-7"
        >
          <v-spacer></v-spacer>
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn text color v-on="on">
                <flag :iso="currentFlag" v-bind:squared="false" />
                <v-icon right color="primary">mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item
                v-for="(entry, index) in languagess"
                :key="`${index}010`"
                @click="changeLocale(entry)"
              >
                <v-list-item-title>
                  <flag :iso="entry.flag" v-bind:squared="false" />
                  &nbsp;{{ entry.title }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-system-bar>
        <v-card class="pb-10">
          <v-row justify="center">
            <v-col
              cols="4"
              md="5"
            >
              <v-responsive class="pt-10">
                <v-img
                  width="300px"
                  src="/logo-kenmare-white.png"
                ></v-img>
              </v-responsive>
            </v-col>
          </v-row>
          <v-card-title class="subtitle-1 text-center justify-center px-12 pb-0 title-card">
            {{ $t('Verify_your_account') }}
          </v-card-title>
          <v-card-title class="subtitle-2 text-center justify-center px-12 mb-10">
            {{ $t('We_have_sent_code_to_email', { message: $route.query.email }) }}
          </v-card-title>
          <v-card-text class="px-10">
            <v-row class="justify-center text-center">
              <v-text-field
                v-model="code"
                :label="`${$t('enter_code_in_this_field')}`"
                placeholder="*****"
                outlined
                type="text"
                dense
                required
              ></v-text-field>
            </v-row>
            <v-row class="d-flex flex-row justify-space-between align-center mt-5 px-3">
              <div class="d-flex">
                <div >{{$t('Do_not_get_code')}}</div>
                <a class="px-1" @click.prevent="reSendCode()">{{ $t('Resend') }}</a>
              </div>
              <div>
                <v-btn
                  class="mx-2"
                  type="reset"
                  @click.prevent="cancel()"
                >{{$t('cancel')}}</v-btn>
                <v-btn
                  :loading="isLoading"
                  :disabled="isLoading"
                  color="primary"
                  @click.prevent="submit"
                >{{$t('Continue')}}</v-btn>
              </div>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { API_URL } from "@/api";
import { GC_AUTH_TOKEN, GC_USER_ID } from "@/constants/settings";
import { VERIFY_TWO_FACTOR_CODE } from "./../graphql/verifyTwoFactorCode";
import { 
  SEND_VERIFY_CODE_EMAIL_MUTATION,
} from './../graphql/mutation.gql';
import { mapActions } from "vuex";
import { getDeviceDetails } from "@/utils";
import accessLevelMixins from "@/mixins/access-level";

export default {
  name: 'VerifyTwoFactorCode',
  mixins: [accessLevelMixins],
  data: () => ({
    apiUrl: API_URL,
    currentFlag: "",
    isInitiate: true,
    isLoading: false,
    isPassword: false,
    fieldSelected: '',
    userAccount: null,
    verifyCode: undefined,
    verificationCodeEnter: undefined,
    password: "",
    repeatPassword: "",
    code: "",
  }),

  created () {
    if (localStorage.getItem("flag") && localStorage.getItem("lang")) {
      this.$i18n.locale = localStorage.getItem("lang");
      this.currentFlag = localStorage.getItem("flag");
    }

    this.noInternet = window.navigator.onLine ? false : true
    // this.fieldSelected = this.$route.query.typeEnter.type;
    // this.userAccount = this.$route.query.userAccount;
    if (this.noInternet) {
      this.error = 'No Internet Connection'
      this.showError = true
    }
  },

  computed: {
    languagess: function() {
      return [
        { flag: "gb", language: "en", title: "English" },
        // { flag: "fr", language: "fr", title: "Français" },
        { flag: "pt", language: "pt", title: "Português" },
      ];
    },
    requiredRules() {
      return [(v) => !!v || `${this.$t('email_required')}!`];
    },
    
  },

  methods: {
    ...mapActions({
      setUserState: "auth/setUser",
    }),
    async submit () {
      this.isLoading = true;
      const email = this.$route.query.email;
      try {
        const { deviceId, deviceName } = await getDeviceDetails();
        const { data } =  await this.$apollo.mutate({
          mutation: VERIFY_TWO_FACTOR_CODE,
          variables: {
            email,
            code: this.code,
            deviceId,
            deviceName,
          }
        });

        const id = data.verifyTwoFactorCode.user.id;
        const token = data.verifyTwoFactorCode.token;
        const loggedUser = data.verifyTwoFactorCode.user;

        if (loggedUser.passwordReset) {
          
          this.saveUserData(id, token);
          this.setUserState(data.verifyTwoFactorCode.user);
  
          let loginUrl = localStorage.getItem("loginUrl")
            ? "/candidate" + localStorage.getItem("loginUrl")
            : localStorage.getItem("loginParams")
            ? "/candidate/"
            : "/candidate";
  
          switch (data.verifyTwoFactorCode.user.type) {
            case "candidate":
              this.$router.push(this.$route.query.redirect || loginUrl);
              break;
            case "entity":
              this.$router.push(
                this.$route.query.redirect || this.setRoute(data.verifyTwoFactorCode.user)
              );
              break;
            case "partner":
              this.$router.push(this.$route.query.redirect || "/partner");
              break;
            case "root":
              this.$router.push(this.$route.query.redirect || "/root");
              break;
          }
        } else {
          this.$router.push({ 
            path: 'reset-password',
            query: {
              any: { id: loggedUser.id, email: loggedUser.email, telephone: loggedUser.telephone },
              typeEnter: { type: "email" },
            },
          });
        }

      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },

    async reSendCode() {
      try {
        await this.$apollo.mutate({
          mutation: SEND_VERIFY_CODE_EMAIL_MUTATION,
          variables: {
            email: this.$route.query.email
          }
        });
      } catch (error) {
        console.log(error);
      }
    },

    saveUserData(id, token) {
      localStorage.setItem(GC_USER_ID, id);
      localStorage.setItem(GC_AUTH_TOKEN, token);
      this.$root.$data.userId = localStorage.getItem(GC_USER_ID);
    },

    setRoute(user) {
      if (this.accessLevel(user, "dashboard")) {
        return "dashboard";
      }
      return "/dashboard/entity/user/profile";
    },

    cancel() {
      this.$router.push({
        path: `login`,
        query: {
          redirect: this.$route.query.redirect,
        },
      })
    },

    changeLocale(locale) {
      this.$i18n.locale = locale.language;
      this.currentFlag = locale.flag;

      localStorage.setItem("lang", locale.language);
      localStorage.setItem("flag", locale.flag);
    },
  }
}
</script>

<style scoped>
.wena {
  background: #2E4C33;
}
.title-card {
  white-space: pre-wrap;
}
</style>