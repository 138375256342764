import gql from "graphql-tag";
import userFragment from "./userFragment";

export const VERIFY_TWO_FACTOR_CODE = gql`
  mutation VerifyTwoFactorCode($email: String!, $code: String!, $deviceId: String, $deviceName: String) {
    verifyTwoFactorCode(email: $email, code: $code, deviceId: $deviceId, deviceName: $deviceName) {
      token
      user {
        ...${userFragment}
      }
    }
  }
`;
